import * as EventTypes from 'shared/eventTypes'

import './content-style.css'
import './button-style.css'

const RISKLINE_WRAPPER_ID = 'riskline-container'
const CONTENT_IFRAME_CLASS = 'riskline-content-iframe'
const BUTTON_IFRAME_CLASS = 'riskline-button-iframe'
const BUTTON_IFRAME_CLASS_SMALL = 'riskline-button-iframe-small'
const BUTTON_IFRAME_CLASS_LARGE = 'riskline-button-iframe-large'

// dev
// const CONTENT_IFRAME_URL = '/index-embed.html'
// const BUTTON_IFRAME_URL = '/index-button.html'

// staging
// const CONTENT_IFRAME_URL = 'https://widget-staging.riskline.com/'
// const BUTTON_IFRAME_URL = 'https://widget-staging.riskline.com/button/button.html';

// production
const CONTENT_IFRAME_URL = 'https://widget.riskline.com/'
const BUTTON_IFRAME_URL = 'https://widget.riskline.com/button/button.html';


class Riskline {

  constructor(onloadFunc = function() {}) {
    this.onloadFunc = onloadFunc
    this.contentIframeMounted = false
    this.container = null
    this.buttonIframe = null
    this.contentIframe = null
  }

  // PUBLIC API

  init = (options) => {
    this.apiKey = options.apiKey
    this.color = options.color
    this.origin = options.origin
    this.destination = options.destination
    this.imageUrl = options.imageUrl

    window.addEventListener("message", this.receiveMessage, false);
    
    this.initializeContainer()
    this.mountContainer()
    this.initializeButtonIframe()
    this.mountButtonIframe()
  }

  receiveMessage = (event) => {
    if (!!event && !!event.data && !!event.data.type) {
      switch(event.data.type) {
        case EventTypes.BUTTON_SMALL:
          this.buttonIframe.classList.add(BUTTON_IFRAME_CLASS_SMALL)
          break;
        case EventTypes.BUTTON_CLICKED:
          if ( this.contentIframeMounted === false ) {
            this.initializeContentIframe();
            this.mountContentIframe();
          }
          this.toggleContent();
          break;
      }
    }
  }

  initializeContainer = () => {
    const wrapper = document.createElement('div')
    wrapper.id = RISKLINE_WRAPPER_ID
    wrapper.style = `z-index: ${Number.MAX_SAFE_INTEGER}; width: 0; height: 0; position: fixed; right: 0; bottom: 0;`
    this.container = wrapper
  }

  mountContainer = () => {
    document.body.appendChild(this.container)
  }

  initializeButtonIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.src = BUTTON_IFRAME_URL
    iframe.className = BUTTON_IFRAME_CLASS
    iframe.classList.add(BUTTON_IFRAME_CLASS_LARGE) 
    iframe.crossorigin = "anonymous"
    this.buttonIframe = iframe
  }

  mountButtonIframe = () => {
    this.container.appendChild(this.buttonIframe)
  }

  initializeContentIframe = () => {
    let queryParamsOptions = [[ "key", this.apiKey ]]
    if (this.color) queryParamsOptions.push([ "color", this.color ])
    if (this.origin) queryParamsOptions.push([ "origin", this.origin ])
    if (this.destination) queryParamsOptions.push([ "destination", this.destination ])
    if (this.imageUrl) queryParamsOptions.push([ "imageUrl", this.imageUrl ])
    const queryParams = queryParamsOptions.map(function(item){
      return `${item[0]}=${item[1]}`
    })

    const iframe = document.createElement('iframe')
    iframe.src = CONTENT_IFRAME_URL + "?" + queryParams.join("&")
    iframe.className = CONTENT_IFRAME_CLASS
    iframe.crossorigin = "anonymous"
    this.contentIframe = iframe
  }

  mountContentIframe = () => {
    this.container.appendChild(this.contentIframe);
    this.contentIframeMounted = true;
  }

  toggleContent = () => {
    this.buttonIframe.classList.toggle("riskline-button-iframe-opened"), 
    this.contentIframe.classList.toggle("riskline-content-iframe-visible");
  }

}

export default ((window) => {
  const onloadFunc = (window.riskline && window.riskline.onload && typeof window.riskline.onload === 'function') ? window.riskline.onload : function(){};
  const initCall = window.riskline._c.find(call => call[0] === 'init');
  const risklineApi = () => {};
  const riskline = new Riskline(onloadFunc);

  risklineApi.init = riskline.init;

  risklineApi[initCall[0]].apply(risklineApi, initCall[1]);
})(global)
